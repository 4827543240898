import axiosLib from 'axios'
import config from '../config'

let axiosInstance;
let userGroup;

const reqConfig = obj => ({
  headers: {
    'Authorization': `Bearer ${obj.idToken}`
  }
})

export default {
  idToken: null,
  async setEndpoint(user) {
    if (!user) {
      console.log("User argument is missing");
      axiosInstance = axiosLib.create({
        baseURL: config.APIEndpoint + "admins-csfb-api"
      });
      return;
    }
  
    try {
      // Create an axios instance
      axiosInstance = axiosLib.create({
        baseURL: config.APIEndpoint + "admins-csfb-api"
      });
  
      // Getting settings
      const response = await axiosInstance.get('/get-settings', reqConfig(this))
      if (!response.data.settings.cdnAdmins && user == "pawelrosada@gmail.com") {
        axiosInstance.defaults.baseURL = config.APIEndpoint + "admins-csfb-api";
        return;
      }
      // Parse CDN_ADMINS
      const settings = response.data.settings;
      const cdnAdminsString = settings.cdnAdmins;
      const cdnAdminsArray = cdnAdminsString.split(',');
      const cdnAdmins = cdnAdminsArray.reduce((acc, item) => {
        const [email, role] = item.split('=');
        acc[email.trim()] = role ? role.trim() : '';
        return acc;
      }, {});

      if (!Object.keys(cdnAdmins).includes(user)) {
        console.error("User email: " + user);
        console.error("CDN_ADMINS:" + JSON.stringify(settings));
        return;
      }
  
      userGroup = cdnAdmins[user];
  
      // Update the axios instance with new baseURL
      console.log("Endpoint set to: " + config.APIEndpoint + userGroup + "-csfb-api")
      axiosInstance.defaults.baseURL = config.APIEndpoint + userGroup + "-csfb-api";
    } catch (error) {
      console.error("Error occurred: ", error);
    }
  },
  getUserGroup() {
    return userGroup;
  },
  getFiles() {
    return axiosInstance.get('/get-files', reqConfig(this))
  },
  checkIsPublic(path) {
    return axiosInstance.head(config.BucketUrl + path + `?bc_timestamp=${new Date().getTime()}`) // Append unused query param to ensure that browser cache is bypassed.
      .then(res => res.status === 200)
      .catch(res => false)
  },
  setPublicOrPrivate(filepath, pub) {
    return axiosInstance.post(pub ? '/set-public' : '/set-private', {
      filepath
    }, reqConfig(this))
  },
  getSharableUrl(filepath, download) {
    return axiosInstance.post('/get-share-url', {
      filepath,
      download
    }, reqConfig(this))
      .then(res => res.data)
  },
  addFolder(folderpath) {
    return axiosInstance.post('/add-folder', {
      folderpath
    }, reqConfig(this))
  },
  deleteFile(filepath) {
    return axiosInstance.post('/delete-file', {
      filepath
    }, reqConfig(this))
  },
  moveFile(filepath, destination) {
    return axiosInstance.post('/move-file', {
      filepath,
      destination
    }, reqConfig(this))
      .then(res => res.data)
  },
  getNewUploadPolicy(filepath, fileContentType, fileSize) {
    return axiosInstance.post('/get-new-upload-policy', {
      filepath,
      fileContentType,
      fileSize,
    }, reqConfig(this))
      .then(res => res.data)
  },
  postFile(uploadPolicy, file, progressCb) {
    const data = new FormData()
    for (const [key, value] of Object.entries(uploadPolicy.fields)) { // Add form fields, including policy and signature, to formdata
      data.append(key, value)
    }
    data.append('file', file) // Add the file to the formdata

    const cancelTokenSource = axiosLib.CancelToken.source()

    const uploadPromise = axiosLib.post(uploadPolicy.url, data, { // Use the axiosLib because it's a different API baseURL
      onUploadProgress: (p) => progressCb(p.loaded / p.total),
      cancelToken: cancelTokenSource.token
    })

    return [uploadPromise, () => cancelTokenSource.cancel()]
  },
  getSettings() {
    return axiosInstance.get('/get-settings', reqConfig(this))
      .then(res => res.data.settings)
  },
  saveSettings(settings) {
    return axiosInstance.post('/save-settings', {
      settings
    }, reqConfig(this))
      .then(res => res.data)
  }
}