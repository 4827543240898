import React, { useState, useEffect } from 'react';
import { Header, Icon, Modal, Button } from 'semantic-ui-react';
import { useGoogleLogin } from '@react-oauth/google';
import { hasGrantedAnyScopeGoogle } from '@react-oauth/google';
import config from '../../config';
import axios from 'axios';

const GoogleAuth = ({ setIdToken, setProfile }) => {
  const [open, setOpen] = useState(true); // Is the sign-in modal open
  const [signingIn, setSigningIn] = useState(false); // Is the user in the process of signing in
  const [error, setError] = useState(false); // Has there been a sign-in error
  const [tokenGoogle, setTokenGoogle] = useState(null);
  const [userData, setUserData] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  // Other state variables as needed

  useEffect(() => {
    // Periodically check if the token needs refreshing
    console.log('[DEBUG] test token validation');
    const interval = setInterval(() => {
      validateToken()
    }, 1000 * 60 * 5); // Example: check every 5 minutes

    return () => clearInterval(interval);
  }, [refreshToken]);

  useEffect(() => {
    const tokenObj = JSON.parse(localStorage.getItem('user'));
    const userData = JSON.parse(localStorage.getItem('userData'));
    setOpen(tokenObj ? false : true);
    logged(userData, tokenObj);
    validateToken();
  
  }, []); // Run only once on component mount to check if user is already signed in

  const logged = async (userData, tokenObj) => {
    console.log(
      '[DEBUG] hasGrantedAnyScopeGoogle: ' +
      hasGrantedAnyScopeGoogle(tokenObj, 'profile', 'email')
    );

    if (tokenObj && tokenObj.scope.includes("email") && tokenObj.scope.includes("profile")) {
      await setIdToken(tokenObj.access_token, userData.email);
      setProfile({
        name: userData.name,
        imageUrl: userData.picture,
        email: userData.email,
      });
      setOpen(false);
    } else {
      setSigningIn(false);
    }
  }

  const onSuccess = async (isSignedIn) => {
    console.log('[DEBUG] onSuccess called');
    console.log(
      '[DEBUG] Profil:' + userData ? JSON.stringify(userData, null, 2) : undefined
    );
    console.log('[DEBUG] isSignedIn: ' + isSignedIn);
    let tokenObj = tokenGoogle; // Get ID token
    console.log(
      '[DEBUG] hasGrantedAnyScopeGoogle: ' +
      hasGrantedAnyScopeGoogle(tokenObj, 'profile', 'email')
    );
    // Save user information to localStorage after successful authentication
    localStorage.setItem('user', JSON.stringify(tokenObj));
    localStorage.setItem('userData', JSON.stringify(userData));

    if (
      isSignedIn &&
      hasGrantedAnyScopeGoogle(tokenObj, 'profile', 'email') &&
      tokenObj
    ) {
      await setIdToken(tokenObj.access_token, userData.email);
      console.log('[DEBUG] setRefreshToken');
      setRefreshToken(tokenObj.refresh_token);
      setProfile({
        name: userData.name,
        imageUrl: userData.picture,
        email: userData.email,
      });
      setOpen(false);
    } else {
      setSigningIn(false);
      setOpen(true);
      localStorage.clear();
    }
  };

  useEffect(() => {
    if (userData && tokenGoogle) {
      onSuccess(true);
    }
  }, [userData, tokenGoogle]);

  const signIn = useGoogleLogin({
    onSuccess: async (TokenResponse) => {
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${TokenResponse.access_token}` },
        })
        .then((res) => res.data);

      setTokenGoogle(TokenResponse);
      setUserData(userInfo);
    },
    onError: (ErrorResponse) => {
      console.log(ErrorResponse);
      setError(true);
    },
  });

  const validateToken = async () => {
    const tokenObj = JSON.parse(localStorage.getItem('user'));
    if (!tokenObj) {
      console.log('[DEBUG] empty token object');
      return;
    }
  
    try {
      // Replace with the appropriate Google API endpoint to validate the token
      const response = await axios.get('https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=' + tokenObj.access_token);
      if (response.status === 200) {
        // Token is valid
        console.log('[DEBUG] Token is valid');
      } else {
        // Token is invalid or expired
        console.log('[DEBUG] Token is invalid or expired');
        localStorage.clear()
        window.location.reload()
      }
    } catch (error) {
      console.error('Error validating token', error);
      localStorage.clear()
      window.location.reload()
    }
  };

  return (
    <Modal basic centered open={open} size='small'>
      <Header icon>
        <Icon
          name={error ? 'warning circle' : signingIn ? 'spinner' : 'sign in'}
          loading={signingIn && !error}
        />
        {error ? 'Something went wrong' : 'Sign In'}
      </Header>
      <Modal.Content>
        <Button
          style={{ display: 'block', margin: '0 auto' }}
          primary
          disabled={signingIn}
          onClick={() => {
            setSigningIn(true);
            signIn();
          }}
        >
          Sign In with Google
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default GoogleAuth;
console.log('[DEBUG] GoogleAuth called');
