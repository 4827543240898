import React, { useState, useReducer, useEffect, createRef } from 'react'
import styles from './FileUploadModal.module.css'
import { Modal, Button, Icon, List, Progress, Checkbox, Popup } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { formatBytes } from '../../util/fileutil'
// import getDroppedOrSelectedFiles from '../../util/upload'
import api from '../../api/storage'
import axios from 'axios'


let uploadCancelFunc = () => { }

const initialUploadState = {
  files: [],
  status: '',
  progress: 0,
  totalProgress: 0,
  totalUploadSize: 0,
  totalUploadedSize: 0,
  uploading: false,
  error: false,
  folderUpload: false,
  uploadCancelled: false
}

function uploadStateReducer(state, action) {
  switch (action.type) {
    case 'reset':
      if (action.betweenSteps) return { ...state, status: action.status || '', progress: 0, error: false }
      else {
        uploadCancelFunc() // Cancel the current upload request.
        return { ...initialUploadState }
      }
    case 'setStatus':
      return { ...state, status: action.status }
    case 'uploadError':
      console.error(action.error)
      return { ...state, error: true, uploading: false, progress: 100, status: action.error + ' (preceding files successfully uploaded)' }
    case 'setUploading':
      return { ...state, uploading: action.uploading }
    case 'setProgress':
      return { ...state, progress: Math.round(action.rawProgress * 100 * 10) / 10 }
    case 'addUploadedAmount':
      const totalUploadedSize = state.totalUploadedSize + action.amount
      return {
        ...state,
        totalUploadedSize,
        totalProgress: totalUploadedSize / state.totalUploadSize * 100
      }
    case 'setFiles':
      let size = 0
      for (const file of action.files) size += file.size || 0 // Count the total size of all the files
      return { ...state, files: action.files || [], totalUploadSize: size }
    case 'switchFolderUpload':
      return { ...state, folderUpload: !state.folderUpload }
  }
}

const FileUploadModal = ({ open, closeModal, path, onSuccess }) => {
  const fileInput = createRef();
  const [state, dispatch] = useReducer(uploadStateReducer, initialUploadState);

  const handleStepFail = (err, message) => {
    console.error(err);
    return Promise.reject(new Error(message));
  };

  const uploadFile = async (file, uploadPolicy) => {
    dispatch({ type: 'setStatus', status: `Uploading file ${file.name}...` });
    const [uploadPromise, cancelFunc] = api.postFile(uploadPolicy, file, (p) => dispatch({ type: 'setProgress', rawProgress: p }));
    await uploadPromise;
    dispatch({ type: 'addUploadedAmount', amount: file.size || 0 });
  };

  const retryUpload = async (file, index, maxAttempts = 3) => {
    let attempt = 1;
    while (attempt <= maxAttempts) {
      try {
        const uploadPolicy = await api.getNewUploadPolicy(file.name, file.type, file.size);
        await uploadFile(file, uploadPolicy);
        return; // Successful upload, exit retry loop
      } catch (err) {
        if (axios.isCancel(err)) {
          throw new Error('Upload cancelled');
        } else if (attempt === maxAttempts) {
          throw new Error(`Unable to upload file ${file.name} after ${maxAttempts} attempts: ${err}`);
        }
        await new Promise(resolve => setTimeout(resolve, 2000 * attempt)); // Exponential back-off
        attempt++;
      }
    }
  };

  const startUpload = async () => {
    const batchSize = 5;
    for (let i = 0; i < state.files.length; i += batchSize) {
      const batch = state.files.slice(i, i + batchSize);
      dispatch({ type: 'reset', betweenSteps: true });
      const uploadPromises = batch.map((file, index) => retryUpload(file, i + index));
      try {
        await Promise.all(uploadPromises);
        if (i + batchSize >= state.files.length) {
          toast.dark("🚀 All files uploaded!");
          dispatch({ type: 'reset' });
          setTimeout(onSuccess, 1000); // Wait one second before closing modal and refreshing explorer
        }
      } catch (err) {
        dispatch({ type: 'uploadError', error: err });
        console.error("Batch upload failed:", err);
        break; // Exit the loop if a batch fails
      }
    }
  };

  const onFilesChange = (event) => {
    const parentPath = path.length ? path.join('/') + '/' : '' // Folder to upload files to
    let fileArray = Array.from(event.target.files).map(file => {
      const fileName = parentPath + (state.folderUpload ? file.webkitRelativePath : file.name) // The absolute destination path of file. webkitRelativePath is the relative path of the file on the user's FS.
      const newFile = new File([file], fileName, { type: file.type })
      return newFile
    })
    if (state.folderUpload) {
      // If it's a folder upload we also have to generate files for each folder so that they show up in the file manager
      let folderPaths = []
      for (const file of fileArray) {
        const fileParentFolder = file.name.split('/').slice(0, -1).join('/') + '/'
        console.log(fileParentFolder)
        if (!folderPaths.includes(fileParentFolder)) folderPaths.push(fileParentFolder)
      }
      folderPaths = folderPaths.map(folderName => new File([''], folderName))
      fileArray = fileArray.concat(folderPaths)
      console.log(fileArray)
    }
    dispatch({ type: 'setFiles', files: fileArray })
  }

  const fileList = state.files.map(file => (
    <List.Item>
      <span className={styles.fileListName}>
        {file.name}
      </span> - {formatBytes(file.size)}
    </List.Item>
  ))

  return (
    <div>
      <Modal open={open}>
        <Modal.Header>Upload Files</Modal.Header>
        <Modal.Content>
          <p>You can select multiple files or a single folder to upload. If you upload a folder, file structure will be preserved. Files will be uploaded to {(path || []).join('/') + '/'}.</p>

          <Checkbox toggle label='Upload a folder' checked={state.folderUpload} onClick={() => dispatch({ type: 'switchFolderUpload' })} />
          {/*<Popup content='' trigger={<Icon name='info circle' />} />*/}

          <div className={styles.fileInputContainer}>
            <input
              style={{ display: 'none' }}
              multiple
              type='file'
              webkitdirectory={state.folderUpload ? '' : undefined} // Seems redundant, but needed for some reason
              mozdirectory={state.folderUpload ? '' : undefined}
              msdirectory={state.folderUpload ? '' : undefined}
              odirectory={state.folderUpload ? '' : undefined}
              directory={state.folderUpload ? '' : undefined}
              ref={fileInput}
              onChange={onFilesChange}
            />
            <Button color='blue' size='huge' style={{ display: 'block', margin: '15px auto' }} onClick={() => fileInput.current.click()} disabled={state.uploading}>
              Select {state.folderUpload ? 'a Folder' : 'Files'}
            </Button>
          </div>

          <div className={styles.fileList}>
            <List>
              {fileList}
            </List>
          </div>
          <p style={{ textAlign: 'right', marginRight: '30px', color: state.error ? 'red' : 'black' }}><strong>{state.status}</strong></p>
          {state.status && <Progress percent={state.progress} color='teal' progress active={state.uploading} error={state.error} />}
          {state.status && state.uploading && <Progress percent={state.totalProgress} color='violet' />}
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => { dispatch({ type: 'reset' }); closeModal(); }}>
            Cancel
          </Button>
          <Button color='orange' onClick={startUpload} disabled={!state.files.length || state.uploading}>
            <Icon name='upload' />
            {state.uploading ? 'Uploading...' : 'Start Upload'}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default FileUploadModal