import React, { useState, useEffect } from 'react'
import { Modal, Button, Checkbox, Icon, Form, Input, Label, Dimmer, Loader } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import api from '../../api/storage'

const SettingsModal = ({ open, closeModal }) => {
  // const [saving, setSaving] = useState(false)
  // const [error, setError] = useState(false)

  const [settings, setSettings] = useState({})

  const [notLoaded, setNotLoaded] = useState(true)

  useEffect(() => {
    if (!open) return
    setNotLoaded(true)
    api.getSettings().then(s => {
      // Default settings:
      setSettings({
        ...s,
        useSettings: true // Indicated that these aren't just the default settings
      })
      setNotLoaded(false)
    })
  }, [open])

  const saveSettings = () => {
    // setSaving(true)
    // setError(false)
    api.saveSettings(settings)
      .then(() => toast.dark('⚙️ Settings saved'))
      .catch(err => toast.dark(`❌ An error occurred and we couldn't save your settings`))
  }

  const close = () => { saveSettings(); closeModal() }

  return (
    <div>
      <Modal open={open} onClose={close} size='large' centered={false} dimmer='inverted'>
        <Modal.Header>Options & Settings</Modal.Header>
        <Modal.Content>
          <Dimmer active={notLoaded} inverted>
            <Loader size='large'>Loading...</Loader>
          </Dimmer>

          <Modal.Description style={{ marginBottom: '15px' }}>
            Your settings will be saved in a file in your storage bucket.
          </Modal.Description>
          <Form>
            <Form.Field>
              <label>CDN Admins</label>
              <p>
                Enter the admins in the format <strong>email=group,email2=group2</strong> (without spaces). Each 'email' will be assigned to a corresponding 'group'. This configuration provides <strong>full read and write access to the storage bucket</strong> and settings for the specified users. They will be able to sign into this dashboard with their Google account.
                <br />
                <br />
                Available groups are: <strong>arek-hds, adam-hds, port-trans, zelewski-transport, total-trans, mariusz-hds, admins</strong>.
                <br />
                For example, <code>admin1@example.com=arek_hds,admin2@example.com=admins</code>.
              </p>
              <Input onChange={e => setSettings({...settings, cdnAdmins: e.currentTarget.value})}>
                <input value={settings.cdnAdmins}/>
              </Input>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {/*<p style={{color: error ? 'red' : 'green', fontWeight: 'bold'}}>*/}
          {/*  {!saving && <Icon name='check'/>}*/}
          {/*  { error ? `An error occurred.` : saving ? 'Saving your settings....' : 'Settings saved' }*/}
          {/*</p>*/}
          <Button onClick={close}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default SettingsModal